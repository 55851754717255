/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable dot-notation */
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';

const renderCategoryResult = (results) => {
  return Object.keys(results).map((key) => {
    return (
      <p key={key} className="poppins-light">
        <span>{`${key} Questions:`}</span>
        &nbsp;
        {results[key].correct}
        /
        {results[key].total}
      </p>
    );
  });
};

const renderResults = (results) => {
  return results.map((result) => {
    return (
      <Card key={result.completed}>
        <Card.Body>
          <div className="card-body-inner">
            <Card.Title className="span-lora-bold title">{`Data Literacy (completed ${result.completed})`}</Card.Title>
            <div className="results-container">
              {renderCategoryResult(result.results)}
              <p className="poppins-bold total">
                <span>Total:</span>
        &nbsp;
                {result.totalCorrect}
                /
                {result.totalQuestions}
              </p>
              <a href={result.report ? result.report : '#'} className={result.report ? 'btn' : 'btn disabled-link'} target="_blank" rel="noreferrer">
                {result.report ? 'View Report' : 'Report Pending'}
              </a>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  });
};

const Results = () => {
  const { results } = useSelector(
    (_S) => ({
      results: _S.DashboardReducer.results,
    }),
    shallowEqual,
  );

  return (
    <div className="results">
      <Card>
        <Card.Header className="">Results</Card.Header>
      </Card>
      {renderResults(results)}
    </div>
  );
};

export default Results;

/* eslint-disable no-param-reassign */
/* eslint-disable object-curly-newline */
import React, { useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import queryString from 'query-string';

import { AuthActionCreators } from 'redux/actions/auth';
import AuthLayout from 'components/Layouts/AuthLayout';
import responsiveHero from 'components/Auth/ResponsiveHero';
import { history } from 'helpers/history';

const setPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password required')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/, 'Password should contain at least one uppercase, one lowercase, one number and one special character ! @ # $ % ^ & *')
    .min(8, 'Password should be minimum 8 characters')
    .max(30, 'Password should be maximum 30 characters'),
  passwordConfirmation: Yup.string()
    .required('Password required')
    .min(8, 'Password should be minimum 8 characters')
    .max(30, 'Password should be maximum 30 characters')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

const SetPassword = () => {
  const { id } = queryString.parse(window.location.search);
  const dispatch = useDispatch();
  const { setPassword } = bindActionCreators(AuthActionCreators, dispatch);
  const { error, isAuthenticated, loading } = useSelector(
    (_S) => ({
      isAuthenticated: _S.AuthReducer.isAuthenticated,
      forgotPasswordSent: _S.AuthReducer.forgotPasswordSent,
      error: _S.AuthReducer.error,
      loading: _S.AuthReducer.loading,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/dashboard');
    }
  }, [isAuthenticated]);

  const handleSetPassword = (values) => {
    values.invitationId = id;
    setPassword(values);
  };

  return (
    <>
      <AuthLayout>
        <div>
          {responsiveHero(isAuthenticated)}
          <Formik
            className="bg-white"
            initialValues={{
              password: '',
              passwordConfirmation: '',
            }}
            validationSchema={setPasswordSchema}
            onSubmit={(values) => handleSetPassword(values)}
          >
            {({ errors, touched }) => (
              <>
                <Form className="auth">
                  <Row>
                    <div>
                      <div>
                        <h2 className="poppins-bold">Set a Password</h2>
                        <p className="subtext">Please set a strong password. This should include at least one uppercase, one lowercase, one number and one special character e.g. ! @ # $ % ^ & *.</p>
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Row>
                        <Col>
                          <div className="mb-4">
                            <label htmlFor="password" className="form-label poppins-light">
                              Password
                            </label>
                            <Field name="password" type="password" className={errors.password ? 'form-control error' : 'form-control'} id="password" />
                            {errors.password && touched.password && (
                              <div className="error-msg poppins-light">
                                <p>
                                  <ErrorMessage name="password" />
                                </p>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="mb-4">
                            <label htmlFor="passwordConfirmation" className="form-label poppins-light">
                              Confirm Password
                            </label>
                            <Field name="passwordConfirmation" type="password" className={errors.passwordConfirmation ? 'form-control error' : 'form-control'} id="passwordConfirmation" />
                            {errors.passwordConfirmation && touched.passwordConfirmation && (
                              <div className="error-msg poppins-light">
                                <p>
                                  <ErrorMessage name="passwordConfirmation" />
                                </p>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12}>
                      <button type="submit" className="btn brand-primary-bg" disabled={loading}>
                        {loading ? 'Loading...' : 'Set Password'}
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>{error && <div className="error-msg poppins-light mt-3">Sorry, something went wrong.</div>}</Col>
                  </Row>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </AuthLayout>
    </>
  );
};

export default SetPassword;

/* eslint-disable no-unused-vars */
import { DashboardActionTypes } from 'redux/types/dashboard';

import { api } from 'helpers/apiClient';

export const DashboardActionCreators = {
  getAssessments: () => async (dispatch) => {
    dispatch({
      type: DashboardActionTypes.GET_ASSESSMENTS_REQ,
    });
    try {
      const response = await api('GET', `${process.env.REACT_APP_API_BASE_URL}/getAssessments`, null, true);
      const res = await response.json();
      dispatch({
        type: DashboardActionTypes.GET_ASSESSMENTS_RES,
        error: null,
        payload: res,
      });
    } catch (e) {
      dispatch({
        type: DashboardActionTypes.GET_ASSESSMENTS_RES,
        error: e?.data?.message || e.message || 'Something went wrong',
      });
    }
  },
  getReports: () => async (dispatch) => {
    dispatch({
      type: DashboardActionTypes.GET_REPORTS_REQ,
    });
    try {
      const response = await api('POST', `${process.env.REACT_APP_API_BASE_URL}/user/getReports`, null, true);
      const res = await response.json();
      dispatch({
        type: DashboardActionTypes.GET_REPORTS_RES,
        error: null,
        payload: res,
      });
    } catch (e) {
      dispatch({
        type: DashboardActionTypes.GET_REPORTS_RES,
        error: e?.data?.message || e.message || 'Something went wrong',
      });
    }
  },
  getCourse: () => async (dispatch) => {
    dispatch({
      type: DashboardActionTypes.GET_COURSE_REQ,
    });
    try {
      const response = await api('GET', `${process.env.REACT_APP_API_BASE_URL}/course`, null, true);
      const res = await response.json();
      dispatch({
        type: DashboardActionTypes.GET_COURSE_RES,
        error: null,
        payload: res,
      });
    } catch (e) {
      dispatch({
        type: DashboardActionTypes.GET_COURSE_RES,
        error: e?.data?.message || e.message || 'Something went wrong',
      });
    }
  },
  // Add getResults() to the 'dashboard' actions that calls the getResults node backend
  getResults: () => async (dispatch) => {
    dispatch({
      type: DashboardActionTypes.GET_RESULTS_REQ,
    });
    try {
      const response = await api('GET', `${process.env.REACT_APP_API_BASE_URL}/getResults`, null, true);
      const res = await response.json();
      dispatch({
        type: DashboardActionTypes.GET_RESULTS_RES,
        error: null,
        payload: res,
      });
    } catch (e) {
      dispatch({
        type: DashboardActionTypes.GET_RESULTS_RES,
        error: e?.data?.message || e.message || 'Something went wrong',
      });
    }
  },
};

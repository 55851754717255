export const AuthActionTypes = {
  RESET_STATE: 'RESET_STATE',
  LOGIN_REQ: 'LOGIN_REQ',
  LOGIN_RES: 'LOGIN_RES',
  FORGOT_PASSWORD_REQ: 'FORGOT_PASSWORD_REQ',
  FORGOT_PASSWORD_RES: 'FORGOT_PASSWORD_RES',
  RESET_PASSWORD_REQ: 'RESET_PASSWORD_REQ',
  RESET_PASSWORD_RES: 'RESET_PASSWORD_RES',
  SET_PASSWORD_REQ: 'SET_PASSWORD_REQ',
  SET_PASSWORD_RES: 'SET_PASSWORD_RES',
  VALIDATE_INVITATION_REQ: 'VALIDATE_INVITATION_REQ',
  VALIDATE_INVITATION_RES: 'VALIDATE_INVITATION_RES',
  AUTHENTICATE_REQ: 'AUTHENTICATE_REQ',
  AUTHENTICATE_RES: 'AUTHENTICATE_RES',
};

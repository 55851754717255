/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import DOMPurify from 'dompurify';

const formatTime = (seconds) => {
  return (seconds - (seconds %= 60)) / 60 + (seconds > 9 ? ':' : ':0') + seconds;
};

const DataLiteracyAnalysis = () => {
  const { literacy } = useSelector(
    (_S) => ({
      literacy: _S.DashboardReducer.literacy,
    }),
    shallowEqual,
  );

  const [counter, setCounter] = useState(300);

  useEffect(() => {
    if (literacy.report) {
      const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [literacy, counter]);

  return (
    <Container>
      <Row className="pb-4">
        <Col md={{ span: 7 }} className="mx-auto text-center dl-copy">
          <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(literacy.ctaMessage) }} />
        </Col>
      </Row>
      <Row className="pb-5">
        <Col md={{ span: 8 }} lg={{ span: 4, offset: 2 }} className="mx-auto text-center">
          <>
            {(literacy.status === 'begin' || literacy.status === 'inProgress') && (
              <Link to="/quiz/1" className="btn">
                {literacy.status === 'begin' && 'Get Started'}
                {literacy.status === 'inProgress' && 'Continue'}
              </Link>
            )}

            {literacy.status === 'completed' && (
              <>
                {(!literacy.report) && (
                  <Link to="" className="btn disabled-link">
                    Report Pending
                  </Link>
                )}

                {literacy.report && (
                  <>
                    <a href={literacy.report} className={counter > 0 ? 'btn' : 'btn disabled-link'} target="_blank" rel="noreferrer">
                      Download Report
                    </a>
                    <p className="mt-3 mb-0">{counter > 0 ? `Your report is available for ${formatTime(counter)}, after which you will need to refresh the browser.` : 'Please refresh the page to retrieve your report again.'}</p>
                  </>
                )}
              </>
            )}
          </>
        </Col>
      </Row>
    </Container>
  );
};

export default DataLiteracyAnalysis;

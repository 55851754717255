/* eslint-disable react/prop-types */
import React from 'react';
import { Row, Col, ProgressBar } from 'react-bootstrap';

const Progress = ({ current, categories }) => {
  const category = categories.find((o) => o.orderNum === current);
  const categoryCount = categories.length;
  const step = 100 / categoryCount;
  const completed = current === 1 ? 0 : current * step;

  return (
    <>
      <Row className="text-center breadcrumb-container bg-white">
        <Col md={{ span: 8, offset: 2 }}>
          <ProgressBar now={completed} label={`Step ${current} of ${categoryCount}`} />
        </Col>
      </Row>
      <Row className="bg-white">
        <Col md={{ span: 8, offset: 2 }}>
          <h1 className="question fs-4 poppins-bold">{category.name}</h1>
        </Col>
      </Row>
    </>
  );
};

export default Progress;

import React from 'react';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

const QuizHeader = ({ id }) => {
  const quizTitle = id === '1' ? 'Data Literacy Analysis' : 'Data Maturity Analysis';
  return (
    <Col xl={{ span: 8, offset: 2 }}>
      <div className="d-flex justify-content-center">
        <div>
          <h1 className="text-white poppins-bold">{quizTitle}</h1>
          {id === '1' && (
            <>
              <p className="quiz-message-subtext">Please complete the questions below to your best knowledge. It will help us know where you are in your understanding of some key data concepts, interpreting data, reading charts and getting value out of your data projects. If you are unsure of any answers please do not guess but select “I don’t know”.</p>
              <p className="quiz-message-subtext">You will receive a report ahead of your training with your results.</p>
            </>
          )}
          {id === '2' && (
            <>
              <p className="quiz-message-subtext">The Data Maturity Analysis is designed to help us understand where your organisation is on its journey with data & AI and recommend areas of focus so you can fully harness the power of data to inform decisions across your business. Whilst you work through, please feel free to ask your colleagues for help to answer relevant questions.</p>
            </>
          )}
        </div>
      </div>
    </Col>
  );
};

QuizHeader.propTypes = {
  id: PropTypes.string.isRequired,
};

export default QuizHeader;

import React, { useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { bindActionCreators } from 'redux';

import { QuizActionCreators } from 'redux/actions/quiz';
import Spinner from 'components/Spinner';
import MainLayout from 'components/Layouts/MainLayout';
import Header from 'components/Layouts/Header';
import Progress from 'components/Progress';
import Questions from 'components/Questions';
import { history } from 'helpers/history';
import QuizHeader from 'components/QuizHeader';

const getCurrentQuestions = (questions, currentCategory) => {
  return questions.filter((question) => {
    if (question.categoryOrder === currentCategory) {
      return question;
    }
    return false;
  });
};

const Quiz = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { getQuiz, saveQuiz } = bindActionCreators(QuizActionCreators, dispatch);
  const {
    status, questions, currentCategory, categories, fetchQuiz,
  } = useSelector(
    (_S) => ({
      questions: _S.QuizReducer.questions,
      currentCategory: _S.QuizReducer.currentCategory,
      categories: _S.QuizReducer.categories,
      status: _S.QuizReducer.status,
      fetchQuiz: _S.QuizReducer.fetchQuiz,
    }),
    shallowEqual,
  );

  const prepAndSave = async (values) => {
    const data = {
      quizId: id,
      currentCategory,
      answers: values,
    };

    saveQuiz(data);
  };

  useEffect(() => {
    if (status === 'completed') {
      history.push('/dashboard');
      return;
    }
    if (fetchQuiz) {
      getQuiz(id);
    }
  }, [status, fetchQuiz]);

  return (
    <>
      <MainLayout header="Data Academy Logo">
        <Header bg="quiz-hero-bg">
          <QuizHeader id={id} />
        </Header>
        <main className="quiz">
          {status === 'succeeded' && (
            <>
              <Progress
                current={currentCategory}
                categories={categories}
              />
              <Questions
                questions={getCurrentQuestions(questions, currentCategory)}
                prepAndSave={prepAndSave}
              />
            </>
          )}
          <Row className="text-center mt-5">
            <div className="mx-auto">{status === 'loading' && <Spinner size="5rem" />}</div>
          </Row>
        </main>
      </MainLayout>
    </>
  );
};

export default Quiz;

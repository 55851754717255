export const DashboardActionTypes = {
  RESET_STATE: 'RESET_STATE',
  GET_ASSESSMENTS_REQ: 'GET_ASSESSMENTS_REQ',
  GET_ASSESSMENTS_RES: 'GET_ASSESSMENTS_RES',
  GET_REPORTS_REQ: 'GET_REPORTS_REQ',
  GET_REPORTS_RES: 'GET_REPORTS_RES',
  GET_COURSE_REQ: 'GET_COURSE_REQ',
  GET_COURSE_RES: 'GET_COURSE_RES',
  // Add GET_RESULTS_REQ and  GET_RESULTS_RES to the 'dashboard' types
  GET_RESULTS_REQ: 'GET_RESULTS_REQ',
  GET_RESULTS_RES: 'GET_RESULTS_RES',
};

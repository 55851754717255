/* eslint-disable no-unused-vars */
import { AuthActionTypes } from 'redux/types/auth';

import { api } from 'helpers/apiClient';

export const AuthActionCreators = {
  resetState: () => async (dispatch) => {
    dispatch({
      type: AuthActionTypes.RESET_STATE,
    });
  },
  login: (values) => async (dispatch) => {
    dispatch({
      type: AuthActionTypes.LOGIN_REQ,
    });
    const data = {
      email: `${values.email}`,
      password: `${values.password}`,
    };
    try {
      const response = await api('POST', `${process.env.REACT_APP_API_BASE_URL}/user/login`, data, false);
      const res = await response.json();
      dispatch({
        type: AuthActionTypes.LOGIN_RES,
        error: null,
        payload: res,
      });
    } catch (e) {
      dispatch({
        type: AuthActionTypes.LOGIN_RES,
        error: e?.data?.message || e.message || 'Something went wrong',
      });
    }
  },
  forgotPassword: (values) => async (dispatch) => {
    dispatch({
      type: AuthActionTypes.FORGOT_PASSWORD_REQ,
    });
    const data = {
      email: `${values.email}`,
    };
    try {
      const response = await api('POST', `${process.env.REACT_APP_API_BASE_URL}/user/forgotpassword`, data, false);
      const res = await response.json();
      dispatch({
        type: AuthActionTypes.FORGOT_PASSWORD_RES,
        error: null,
        payload: res,
      });
    } catch (e) {
      dispatch({
        type: AuthActionTypes.FORGOT_PASSWORD_RES,
        error: e?.data?.message || e.message || e?.email_delivery?.error || 'Something went wrong',
      });
    }
  },
  resetPassword: (values) => async (dispatch) => {
    dispatch({
      type: AuthActionTypes.RESET_PASSWORD_REQ,
    });
    const data = {
      password: `${values.password}`,
      uid: `${values.uid}`,
    };

    try {
      const response = await api('POST', `${process.env.REACT_APP_API_BASE_URL}/user/resetpassword`, data, false);
      const res = await response.json();
      dispatch({
        type: AuthActionTypes.RESET_PASSWORD_RES,
        error: null,
        payload: res,
      });
    } catch (e) {
      dispatch({
        type: AuthActionTypes.RESET_PASSWORD_RES,
        error: e?.data?.message || e.message || e?.email_delivery?.error || 'Something went wrong',
      });
    }
  },
  setPassword: (values) => async (dispatch) => {
    dispatch({
      type: AuthActionTypes.SET_PASSWORD_REQ,
    });
    const data = {
      password: `${values.password}`,
      invitationId: `${values.invitationId}`,
    };
    try {
      const response = await api('POST', `${process.env.REACT_APP_API_BASE_URL}/user/setpassword`, data, false);
      const res = await response.json();
      dispatch({
        type: AuthActionTypes.SET_PASSWORD_RES,
        error: null,
        payload: res,
      });
    } catch (e) {
      dispatch({
        type: AuthActionTypes.SET_PASSWORD_RES,
        error: e?.data?.message || e.message || 'Something went wrong',
      });
    }
  },
  validateInvitation: (values) => async (dispatch) => {
    dispatch({
      type: AuthActionTypes.VALIDATE_INVITATION_REQ,
    });
    const data = {
      invitationId: `${values}`,
    };
    try {
      const response = await api('POST', `${process.env.REACT_APP_API_BASE_URL}/user/invitationvalidate`, data, false);
      const res = await response.json();
      dispatch({
        type: AuthActionTypes.VALIDATE_INVITATION_RES,
        error: null,
        payload: res,
      });
    } catch (e) {
      dispatch({
        type: AuthActionTypes.VALIDATE_INVITATION_RES,
        error: e?.data?.message || e.message || 'Something went wrong',
      });
    }
  },
  authenticate: (values) => async (dispatch) => {
    dispatch({
      type: AuthActionTypes.AUTHENTICATE_REQ,
    });
    try {
      const response = await api('GET', `${process.env.REACT_APP_API_BASE_URL}/authenticate`, null, true);
      const res = await response.json();
      dispatch({
        type: AuthActionTypes.AUTHENTICATE_RES,
        error: null,
        payload: res,
      });
    } catch (e) {
      dispatch({
        type: AuthActionTypes.AUTHENTICATE_RES,
        error: e?.data?.message || e.message || 'Something went wrong',
      });
    }
  },
};

import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';

const Client = () => {
  const { client } = useSelector(
    (_S) => ({
      client: _S.DashboardReducer.client,
    }),
    shallowEqual,
  );

  return (
    <div className="client">
      <Card>
        <Card.Header className="">Client</Card.Header>
      </Card>
      <Card>
        <Card.Body>
          <p className="name">{client.name}</p>
          {client.description != null && <p className="description">{client.description}</p>}
        </Card.Body>
      </Card>
    </div>
  );
};

export default Client;

import { combineReducers } from 'redux';
import { AuthReducer } from './auth';
import { DashboardReducer } from './dashboard';
import { QuizReducer } from './quiz';

const reducers = combineReducers({
  AuthReducer,
  DashboardReducer,
  QuizReducer,
});

export default reducers;

/* eslint-disable max-len */
/* eslint-disable yoda, no-return-assign, no-param-reassign, jsx-a11y/anchor-is-valid,
react/jsx-one-expression-per-line, object-curly-newline */
import React, { useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { DashboardActionCreators } from 'redux/actions/dashboard';
import MainLayout from 'components/Layouts/MainLayout';
import Header from 'components/Layouts/Header';
import ReadingList from 'components/ReadingList';
import YourSchedule from 'components/YourSchedule';
import Client from 'components/Client';
import Supervisors from 'components/Supervisors';
import Peers from 'components/Peers';
import Project from 'components/Project';
import DataLiteracyAnalysis from 'components/DataLiteracyAnalysis';
import DataMaturityAnalysis from 'components/DataMaturityAnalysis';
import Results from 'components/Results';

const Dashboard = () => {
  const dispatch = useDispatch();
  const { getAssessments, getCourse, getResults } = bindActionCreators(DashboardActionCreators, dispatch);

  const { loading, isAuthenticated } = useSelector(
    (_S) => ({
      loading: _S.AuthReducer.loading,
      isAuthenticated: _S.AuthReducer.isAuthenticated,
    }),
    shallowEqual,
  );

  const { maturity, client, supervisors, peers, project, results } = useSelector(
    (_S) => ({
      maturity: _S.DashboardReducer.maturity,
      client: _S.DashboardReducer.client,
      supervisors: _S.DashboardReducer.supervisors,
      peers: _S.DashboardReducer.peers,
      project: _S.DashboardReducer.project,
      results: _S.DashboardReducer.results,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (isAuthenticated) {
      getAssessments();
      getCourse();
      // Append getResults() action to the useEffect hook in Dashboard.js
      getResults();
    }
  }, [isAuthenticated]);

  return (
    <>
      <MainLayout>
        {!loading && (
          <>
            <Header>
              <Container fluid className="quiz-cta">
                <Row className="pt-4">
                  <Col md={{ span: 8 }} className="mx-auto text-center">
                    <h1 className="dl-title fw-bolder">Welcome to the Data Academy!</h1>
                  </Col>
                </Row>
                <DataLiteracyAnalysis />
              </Container>
            </Header>
            <main className="dashboard">
              <Container>
                {maturity.status !== 'not applicable' && <DataMaturityAnalysis />}
                <Row className="pt-lg-5 pb-lg-5 content">
                  <Col lg={{ span: 7, offset: 0 }} md={{ span: 10, offset: 1 }} className="dashboard-left">
                    {project !== null && <Project />}
                    {client !== null && <Client />}
                    <YourSchedule />
                    {results.length > 0 && <Results />}
                  </Col>
                  <Col lg={{ span: 5, offset: 0 }} md={{ span: 10, offset: 1 }} className="dashboard-right">
                    <ReadingList />
                    {supervisors.length > 0 && <Supervisors />}
                    {peers.length > 0 && <Peers />}
                  </Col>
                </Row>
              </Container>
            </main>
          </>
        )}
      </MainLayout>
    </>
  );
};

export default Dashboard;

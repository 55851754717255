import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';

import { AuthActionCreators } from 'redux/actions/auth';
import Spinner from 'components/Spinner';
import AuthLayout from 'components/Layouts/AuthLayout';
import { history } from 'helpers/history';

const Invitation = () => {
  const { id } = queryString.parse(window.location.search);
  const dispatch = useDispatch();
  const { validateInvitation, resetState } = bindActionCreators(AuthActionCreators, dispatch);

  const {
    loading, error, invitationVerified, passwordSet,
  } = useSelector(
    (_S) => ({
      loading: _S.AuthReducer.loading,
      error: _S.AuthReducer.error,
      invitationVerified: _S.AuthReducer.invitationVerified,
      passwordSet: _S.AuthReducer.passwordSet,
    }),
    shallowEqual,
  );

  useEffect(() => {
    resetState();
    validateInvitation(id);
  }, []);

  useEffect(() => {
    if (!passwordSet) {
      history.push(`/setpassword?id=${id}`);
    } else {
      history.push('/login');
    }
  }, [invitationVerified]);

  return (
    <>
      <AuthLayout>
        <div className="auth">
          {loading && !invitationVerified && <Spinner text="Loading..." />}
          {error && <h2 className="invitation-error poppins-light">Sorry, you are currently unable to take part.</h2>}
        </div>
      </AuthLayout>
    </>
  );
};

export default Invitation;

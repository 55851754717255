/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { AuthActionCreators } from 'redux/actions/auth';
import Logo from 'components/Logo';
import { history } from 'helpers/history';
import menuIcon from 'assets/icons/dots.svg';

const NavBar = () => {
  const [route, setRoute] = useState('/login');
  const dispatch = useDispatch();
  const { resetState } = bindActionCreators(AuthActionCreators, dispatch);
  const { isAuthenticated } = useSelector(
    (_S) => ({
      isAuthenticated: _S.AuthReducer.isAuthenticated,
    }),
    shallowEqual,
  );

  const handleSignOut = () => {
    resetState();
    // TODO: history or navigate? see useNavigate() above
    // const navigate = useNavigate();
    history.push('/login');
  };

  useEffect(() => {
    (isAuthenticated) ? setRoute('/dashboard') : setRoute('/login');
  }, [isAuthenticated]);

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <Link to={route} className="navbar-brand">
            <Logo className="" />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <img src={menuIcon} className="menu" alt="menu icon" />
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link onClick={() => handleSignOut()}>Log out</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;

/* eslint-disable no-param-reassign */
/* eslint-disable react/no-danger */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-continue */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Card, Accordion } from 'react-bootstrap';
import DOMPurify from 'dompurify';
import { getStandardDate, formatTime } from 'helpers/date-time-formatter';

const singleModuleDay = (module) => {
  return (
    <Card key={module.startTime}>
      <Card.Body>
        <Card.Title className="module">{`Module ${module.title}`}</Card.Title>
        <p className="name">{module.name}</p>
        {module.type === 'module' && (
          <>
            <p className="duration">0.5 days</p>
            <p className="location">
              <span className="span-lora-bold">Location:</span>
              &nbsp;
              <a href={module.location} target="_blank" rel="noreferrer">
                Link to Zoom
              </a>
            </p>
            <p className="date">
              <span className="span-lora-bold">Date:</span>
              &nbsp;
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(getStandardDate(module.startTime)),
                }}
              />
            </p>
            <p className="time">
              <span className="span-lora-bold">Time:</span>
              &nbsp;
              {formatTime(module.startTime, module.endTime)}
            </p>
            <hr className="border-2 border-top" />
            <p className="name">Preparation</p>
            <p
              className="description"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(module.preparation).replace(
                  /href/g,
                  "target='_blank' href",
                ),
              }}
            />
          </>
        )}
        {module.type === 'assignment' && (
          <p className="deadline">
            <span className="span-lora-bold">Deadline:</span>
            &nbsp;
            {getStandardDate(module.startTime)}
          </p>
        )}
      </Card.Body>
    </Card>
  );
};

const doubleModuleDay = (day) => {
  const [moduleA, moduleB] = day.map((x) => x);
  return (
    <Card key={moduleA.startTime}>
      <Card.Body>
        <Card.Title className="module">
          {`Modules ${moduleA.title} and ${moduleB.title}`}
        </Card.Title>
        <p className="name">{`${moduleA.name} and ${moduleB.name}`}</p>
        <p className="duration">1 day</p>
        <p className="location">
          <span className="span-lora-bold">Location:</span>
          &nbsp;
          <a href={moduleA.location} target="_blank" rel="noreferrer">
            Link to Zoom
          </a>
        </p>
        <p className="date">
          <span className="span-lora-bold">Date:</span>
          &nbsp;
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(getStandardDate(moduleA.startTime)),
            }}
          />
        </p>
        <p className="time">
          <span className="span-lora-bold">Time:</span>
          &nbsp;
          {formatTime(moduleA.startTime, moduleB.endTime)}
        </p>
        <hr className="border-2 border-top" />
        <p className="name">Preparation</p>
        <p
          className="description"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(moduleA.preparation).replace(
              /href/g,
              "target='_blank' href",
            ),
          }}
        />
      </Card.Body>
    </Card>
  );
};

const response = (message) => {
  return (
    <Card>
      <Card.Body>
        <p className="name mb-2">{message}</p>
      </Card.Body>
    </Card>
  );
};

const renderModules = (days) => {
  if (!days.length) return response('No modules available.');
  return days.map((day) => {
    switch (day.length) {
      case 1:
        return singleModuleDay(day[0]);
      case 2:
        return doubleModuleDay(day);
      default:
        return null;
    }
  });
};

const countModules = (days) => {
  const count = days.reduce((acc, curr) => {
    acc += curr.length;
    return acc;
  }, 0);
  const message = `${count} module`;
  return count > 1 ? `${message}s` : message;
};

const pastContent = (days) => {
  return (
    <Card className="past-modules">
      <Accordion flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header className="name">Past modules</Accordion.Header>
          <p className="module-count">{countModules(days)}</p>
          <Accordion.Body>{renderModules(days)}</Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Card>
  );
};

const YourSchedule = () => {
  const [past, setPast] = useState(null);
  const [future, setFuture] = useState(null);
  const { pastDays, futureDays } = useSelector(
    (_S) => ({
      pastDays: _S.DashboardReducer.pastDays,
      futureDays: _S.DashboardReducer.futureDays,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (pastDays.length) setPast(pastContent(pastDays));
    setFuture(renderModules(futureDays));
  }, [pastDays, futureDays]);

  return (
    <div className="your-schedule">
      <Card>
        <Card.Header className="">Your Schedule</Card.Header>
      </Card>
      {past}
      {future}
    </div>
  );
};

export default YourSchedule;

/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';

import Logo from 'components/Logo';
import logoAuth from 'assets/images/Data-Academy-logo-white.svg';
import Message from 'components/Auth/Message';

const AuthLayout = ({ children }) => {
  const { isAuthenticated } = useSelector(
    (_S) => ({
      isAuthenticated: _S.AuthReducer.isAuthenticated,
    }),
    shallowEqual,
  );

  return (
    <>
      <main className="auth-wrapper">
        <div className="d-none d-md-block">
          <Link to={isAuthenticated ? '/dashboard' : '/login'} className="logo-link">
            <Logo className="logo-auth" logo={logoAuth} />
          </Link>
          <p className="footer-text">
            Data Academy by Profusion @
            {new Date().getFullYear()}
            . All rights reserved.
          </p>
        </div>
        <Container fluid>
          <Row>
            <Col sm={{ span: 12 }} md={{ span: 6, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 4, offset: 1 }} className="px-0 d-flex flex-column min-vh-100 justify-content-center">
              {children}
            </Col>
            <Message />
          </Row>
        </Container>
      </main>
    </>
  );
};

export default AuthLayout;

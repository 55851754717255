import PropTypes from 'prop-types';
import React from 'react';

const Spinner = ({ size, color }) => {
  return (
    <div className={`spinner-grow ${color}`} style={{ height: size, width: size }} role="status">
      <span className="visually-hidden" />
    </div>
  );
};

Spinner.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

Spinner.defaultProps = {
  size: 'em',
  color: 'text-dark',
};

export default Spinner;

/* eslint-disable no-unused-vars */
import { QuizActionTypes } from 'redux/types/quiz';

import { api } from 'helpers/apiClient';

export const QuizActionCreators = {
  getQuiz: (quizId) => async (dispatch) => {
    dispatch({
      type: QuizActionTypes.GET_QUIZ_REQ,
    });
    try {
      const data = { quizId };
      const response = await api('POST', `${process.env.REACT_APP_API_BASE_URL}/getQuiz`, data, true);
      const res = await response.json();
      dispatch({
        type: QuizActionTypes.GET_QUIZ_RES,
        error: null,
        payload: res.quiz,
      });
    } catch (e) {
      dispatch({
        type: QuizActionTypes.GET_QUIZ_RES,
        error: e?.data?.message || e.message || 'Something went wrong',
      });
    }
  },
  saveQuiz: (data) => async (dispatch) => {
    dispatch({
      type: QuizActionTypes.SAVE_QUIZ_REQ,
    });
    try {
      const response = await api('POST', `${process.env.REACT_APP_API_BASE_URL}/saveQuiz`, data, true);
      const res = await response.json();
      dispatch({
        type: QuizActionTypes.SAVE_QUIZ_RES,
        error: null,
        payload: res,
      });
    } catch (e) {
      dispatch({
        type: QuizActionTypes.SAVE_QUIZ_RES,
        error: e?.data?.message || e.message || 'Something went wrong',
      });
    }
  },
};

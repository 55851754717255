import { DashboardActionTypes } from 'redux/types/dashboard';

export const initialState = {
  loading: false,
  literacy: false,
  maturity: false,
  quiz1Report: false,
  quiz2Report: false,
  course: null,
  pastDays: [],
  futureDays: [],
  assets: [],
  client: null,
  supervisors: [],
  peers: [],
  project: null,
  error: false,
  // Add a 'results' property to the 'dashboard' reducer's initial state, defaults to empty array
  results: [],
};

export const DashboardReducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case DashboardActionTypes.GET_ASSESSMENTS_REQ: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case DashboardActionTypes.GET_ASSESSMENTS_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          error,
        };
      }
      return {
        ...state,
        loading: false,
        error: false,
        literacy: payload.literacy,
        maturity: payload.maturity,
      };
    }
    case DashboardActionTypes.GET_REPORTS_REQ: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case DashboardActionTypes.GET_REPORTS_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          error,
        };
      }
      return {
        ...state,
        loading: false,
        error: false,
        quiz1Report: payload.quiz1Report,
        quiz2Report: payload.quiz2Report,
      };
    }
    case DashboardActionTypes.GET_COURSE_REQ: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case DashboardActionTypes.GET_COURSE_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          error,
        };
      }
      return {
        ...state,
        loading: false,
        error: false,
        course: payload.course,
        pastDays: payload.pastDays,
        futureDays: payload.futureDays,
        assets: payload.assets,
        client: payload.client,
        supervisors: payload.supervisors,
        peers: payload.peers,
        project: payload.project,
      };
    }
    // Add GET_RESULTS_REQ and GET_RESULTS_RES cases to the DashboardReducer,
    // assigning the array returned from getResults to the 'results' state property
    case DashboardActionTypes.GET_RESULTS_REQ: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case DashboardActionTypes.GET_RESULTS_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          error,
        };
      }
      return {
        ...state,
        loading: false,
        error: false,
        results: payload,
      };
    }
    default:
      return state;
  }
};

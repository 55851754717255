/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
import React, { useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { AuthActionCreators } from 'redux/actions/auth';
import { history } from 'helpers/history';
import AuthLayout from 'components/Layouts/AuthLayout';
import responsiveHero from 'components/Auth/ResponsiveHero';

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email format').required('Email required'),
});

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const user = localStorage.getItem('user');
  const { forgotPassword, resetState } = bindActionCreators(AuthActionCreators, dispatch);
  const { isAuthenticated, forgotPasswordSent, error, loading } = useSelector(
    (_S) => ({
      isAuthenticated: _S.AuthReducer.isAuthenticated,
      forgotPasswordSent: _S.AuthReducer.forgotPasswordSent,
      error: _S.AuthReducer.error,
      loading: _S.AuthReducer.loading,
    }),
    shallowEqual,
  );

  useEffect(() => {
    resetState();
  }, []);

  useEffect(() => {
    if (user !== null) {
      history.push('/dashboard');
    }
  }, [isAuthenticated, user]);

  // handle button click of login form
  const handleForgotPassword = (values) => {
    dispatch(forgotPassword(values));
  };

  return (
    <>
      <AuthLayout>
        <div>
          {responsiveHero(isAuthenticated)}
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={forgotPasswordSchema}
            onSubmit={(values) => handleForgotPassword(values)}
          >
            {({ errors, touched }) => (
              <>
                <Form className="auth">
                  <Row>
                    <div>
                      <h2 className="poppins-bold">Forgot your password?</h2>
                      <p>Please provide your email address and we will send you a password reset link.</p>
                    </div>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-4">
                        <label htmlFor="email" className="form-label poppins-light">
                          Email Address
                        </label>
                        <Field name="email" type="email" className={errors.email ? 'form-control error' : 'form-control'} id="email" />
                        {errors.email && touched.email && (
                          <div className="error-msg poppins-light">
                            <p>
                              <ErrorMessage name="email" />
                            </p>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <button type="submit" className="btn brand-primary-bg" disabled={loading}>
                        {loading ? 'Loading...' : 'Submit'}
                      </button>
                    </Col>
                  </Row>
                  {error && (
                    <Row>
                      <Col xs={12}>{error && <div className="error-msg poppins-light mt-3">Sorry, something went wrong.</div>}</Col>
                    </Row>
                  )}
                  {forgotPasswordSent && (
                    <Row>
                      <Col xs={12}>
                        <div className="mt-3 poppins-light">Please check your email.</div>
                      </Col>
                    </Row>
                  )}
                </Form>
              </>
            )}
          </Formik>
        </div>
      </AuthLayout>
    </>
  );
};

export default ForgotPassword;

import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';

const renderReadingList = (reading) => {
  return reading.map((asset) => {
    return (
      <Card key={asset.id}>
        <Card.Body>
          <p className="name">{asset.name}</p>
          {asset.description != null && <p className="description">{asset.description}</p>}
          <p className="file-size">{asset.size}</p>
          <a
            download={asset.name}
            target="_blank"
            rel="noreferrer"
            href={`module_assets/${asset.location}`}
            className="download"
          >
            <span className="title">Download</span>
            <i className="material-icons">east</i>
          </a>
        </Card.Body>
      </Card>
    );
  });
};

const response = (message) => {
  return (
    <Card>
      <Card.Body>
        <p className="name mb-2">{message}</p>
      </Card.Body>
    </Card>
  );
};

const ReadingList = () => {
  const [content, setContent] = useState(null);

  const { assets } = useSelector(
    (_S) => ({
      assets: _S.DashboardReducer.assets,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (assets.length) {
      setContent(renderReadingList(assets));
    } else {
      setContent(response('No pre-reading for your next training session.'));
    }
  }, [assets]);

  return (
    <div className="reading-list-and-materials">
      <Card>
        <Card.Header className="">Reading list and Materials</Card.Header>
      </Card>
      {content}
    </div>
  );
};

export default ReadingList;

import PropTypes from 'prop-types';
import logoDefault from 'assets/images/Data-Academy-logo-black.svg';

const Logo = ({ logo, className }) => {
  return <img className={className} alt="Data Academy Logo" src={logo} />;
};

Logo.propTypes = {
  logo: PropTypes.string,
  className: PropTypes.string.isRequired,
};

Logo.defaultProps = {
  logo: logoDefault,
};

export default Logo;

/* eslint-disable no-param-reassign */
import * as Yup from 'yup';

export const setInitialValues = (questions) => {
  return questions.reduce((obj, item) => {
    const key = `${item.id}`;

    switch (item.type) {
      case 'checkbox':
        obj[key] = [];
        break;
      default:
        obj[key] = '';
    }

    return obj;
  }, {});
};

export const setValidationRules = (questions) => {
  return questions.reduce((obj, item) => {
    const key = `${item.id}`;

    switch (item.type) {
      case 'checkbox':
        obj[key] = Yup.array().min(1, 'Please select at least 1 option');
        break;
      default:
        obj[key] = Yup.string().required('Please select an answer');
    }

    return obj;
  }, {});
};

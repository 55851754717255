import { QuizActionTypes } from 'redux/types/quiz';

export const initialState = {
  loading: true,
  currentCategory: 1,
  categories: [],
  questions: [],
  results: [],
  status: 'loading',
  fetchQuiz: 1,
};

export const QuizReducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case QuizActionTypes.RESET_STATE:
      return initialState;
    case QuizActionTypes.GET_QUIZ_REQ: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case QuizActionTypes.GET_QUIZ_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          status: payload.completed === 1 ? 'completed' : 'succeeded',
          error,
        };
      }
      return {
        ...state,
        loading: false,
        error: false,
        currentCategory: payload.currentCategory,
        categories: payload.categories,
        questions: payload.questions,
        status: payload.completed === 1 ? 'completed' : 'succeeded',
        fetchQuiz: 0,
      };
    }
    case QuizActionTypes.SAVE_QUIZ_REQ: {
      return {
        ...state,
        loading: true,
        status: 'loading',
        error: false,
      };
    }
    case QuizActionTypes.SAVE_QUIZ_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          status: 'failed',
          error,
        };
      }
      return {
        ...state,
        loading: false,
        error: false,
        fetchQuiz: payload.saved,
      };
    }
    default:
      return state;
  }
};

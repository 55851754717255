import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import 'assets/css/app.css';
import 'assets/css/media.css';
import { Router, Route } from 'react-router-dom';

import Quiz from 'pages/Quiz';
import Invitation from 'pages/Invitation';
import SetPassword from 'pages/SetPassword';
import ResetPassword from 'pages/ResetPassword';
import Login from 'pages/Login';
import ForgotPassword from 'pages/ForgotPassword';
import Dashboard from 'pages/Dashboard';

import { history } from 'helpers/history';
import PrivateRoute from 'routes/private.route';

function App() {
  return (
    <div className="App">
      <Router history={history}>
        <Route exact path="/" component={Login} />
        <Route exact path="/invitation" component={Invitation} />
        <Route exact path="/setpassword" component={SetPassword} />
        <Route exact path="/resetpassword" component={ResetPassword} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgotpassword" component={ForgotPassword} />
        <PrivateRoute path="/quiz/:id" component={Quiz} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
      </Router>
    </div>
  );
}

export default App;

import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Card, Carousel } from 'react-bootstrap';

const renderCarouselItems = (peers) => {
  return peers.map((peer) => {
    return (
      <Carousel.Item key={peer.lastName}>
        <img className="d-block w-100" src={peer.profileImage} alt="First slide" />
        <Carousel.Caption>
          <h3 className="name">
            {peer.firstName}
            &nbsp;
            {peer.lastName}
          </h3>
          <a className="poppins-light email" href={`mailto: ${peer.email}`}>
            {peer.email}
          </a>
          <p className="description">{peer.aboutMe}</p>
        </Carousel.Caption>
      </Carousel.Item>
    );
  });
};

const Peers = () => {
  const { peers } = useSelector(
    (_S) => ({
      peers: _S.DashboardReducer.peers,
    }),
    shallowEqual,
  );

  return (
    <div className="client">
      <Card>
        <Card.Header className="">Peers</Card.Header>
      </Card>
      <Card>
        <Card.Body>
          <Carousel>{renderCarouselItems(peers)}</Carousel>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Peers;

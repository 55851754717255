/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import DOMPurify from 'dompurify';

import infoIcon from 'assets/icons/info.png';

const formatTime = (seconds) => {
  return (seconds - (seconds %= 60)) / 60 + (seconds > 9 ? ':' : ':0') + seconds;
};

const DataMaturityAnalysis = () => {
  const { maturity } = useSelector(
    (_S) => ({
      maturity: _S.DashboardReducer.maturity,
    }),
    shallowEqual,
  );

  const [counter, setCounter] = useState(300);

  useEffect(() => {
    if (maturity.report) {
      const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [maturity, counter]);

  return (
    <Row className="pt-lg-5 content">
      <Col md={{ span: 10, offset: 1 }} lg={{ span: 12, offset: 0 }}>
        <Card className="mb-0 data-maturity-cta py-lg-4 p-4">
          <Row className="h-100">
            <Col md={{ span: 12 }} lg={{ span: 1 }} className="my-lg-auto mb-md-3">
              <div className="ms-lg-3 ms-md-0">
                <img src={infoIcon} className="mx-auto d-block" alt="info icon" />
              </div>
            </Col>
            <Col md={{ span: 12 }} lg={{ span: 8 }} className="my-lg-auto my-3">
              <div className="my-auto">
                <p className="card-text copy poppins-light" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(maturity.ctaMessage) }} />
              </div>
            </Col>
            <Col md={{ span: 12 }} lg={{ span: 3 }} className="my-auto text-center">
              <>
                {(maturity.status === 'begin' || maturity.status === 'inProgress') && (
                  <Link to="/quiz/2" className="btn">
                    {maturity.status === 'begin' && 'Get Started'}
                    {maturity.status === 'inProgress' && 'Continue'}
                  </Link>
                )}

                {maturity.status === 'completed' && (
                  <>
                    {(!maturity.report) && (
                      <Link to="" className="btn disabled-link">
                        Report Pending
                      </Link>
                    )}

                    {maturity.report && (
                      <>
                        <a href={maturity.report} className={counter > 0 ? 'btn' : 'btn disabled-link'} target="_blank" rel="noreferrer">
                          Download Report
                        </a>
                        <p className="mt-3 mb-0">{counter > 0 ? `Your report is available for ${formatTime(counter)}, after which you will need to refresh the browser.` : 'Please refresh the page to retrieve your report again.'}</p>
                      </>
                    )}
                  </>
                )}
              </>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default DataMaturityAnalysis;

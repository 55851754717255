/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

// import { history } from 'helpers/history';
import { AuthActionCreators } from 'redux/actions/auth';
import Spinner from 'components/Spinner';
import NavBar from 'components/Main/NavBar';

const MainLayout = ({ children }) => {
  const dispatch = useDispatch();
  const { authenticate } = bindActionCreators(AuthActionCreators, dispatch);

  const { loading, isAuthenticated } = useSelector(
    (_S) => ({
      loading: _S.AuthReducer.loading,
      isAuthenticated: _S.AuthReducer.isAuthenticated,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (!isAuthenticated) {
      authenticate();
    } else {
      // console.log('user IS authenticated');

      // history.push('/login');
    }
  }, [isAuthenticated]);

  // useEffect(() => {
  //   if (user === null && !isAuthenticated) {
  //     history.push('/login');
  //   }
  // }, [user, isAuthenticated]);

  return (
    <>
      <NavBar />
      <Container fluid>
        {loading && !isAuthenticated && (
          <Container fluid>
            <Row className="py-5 quiz-cta">
              <Col md={{ span: 8 }} lg={{ span: 6 }} className="mx-auto text-center">
                <Spinner text="Loading..." />
              </Col>
            </Row>
          </Container>
        )}
        {!loading && isAuthenticated && children}
      </Container>
    </>
  );
};

export default MainLayout;

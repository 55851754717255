import { AuthActionTypes } from 'redux/types/auth';

export const initialState = {
  loading: false,
  isAuthenticated: false,
  passwordSet: false,
  invitationVerified: false,
  forgotPasswordSent: false,
  passwordReset: false,
  literacy: false,
  maturity: false,
  quiz1: false,
  quiz2: false,
  getUrlPending: false,
  quiz1Report: false,
  quiz2Report: false,
  quizReportError: false,
  error: false,
};

export const AuthReducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case AuthActionTypes.RESET_STATE:
      localStorage.clear();
      return initialState;
    case AuthActionTypes.LOGIN_REQ: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case AuthActionTypes.LOGIN_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          error,
        };
      }
      localStorage.setItem('token', JSON.stringify(payload.token));
      localStorage.setItem('refreshToken', JSON.stringify(payload.refreshToken));
      localStorage.setItem('user', JSON.stringify(payload.user));
      return {
        ...state,
        loading: false,
        error: false,
        isAuthenticated: true,
      };
    }
    case AuthActionTypes.FORGOT_PASSWORD_REQ: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case AuthActionTypes.FORGOT_PASSWORD_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          error,
        };
      }
      return {
        ...state,
        loading: false,
        error: false,
        forgotPasswordSent: true,
      };
    }
    case AuthActionTypes.RESET_PASSWORD_REQ: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case AuthActionTypes.RESET_PASSWORD_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          error,
        };
      }
      return {
        ...state,
        loading: false,
        error: false,
        passwordReset: true,
      };
    }
    case AuthActionTypes.SET_PASSWORD_REQ: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case AuthActionTypes.SET_PASSWORD_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          error,
        };
      }
      localStorage.setItem('token', JSON.stringify(payload.token));
      localStorage.setItem('refreshToken', JSON.stringify(payload.refreshToken));
      localStorage.setItem('user', JSON.stringify(payload.user));
      return {
        ...state,
        loading: false,
        error: false,
        isAuthenticated: true,
      };
    }
    case AuthActionTypes.VALIDATE_INVITATION_REQ: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case AuthActionTypes.VALIDATE_INVITATION_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          error,
        };
      }

      return {
        ...state,
        loading: false,
        error: false,
        passwordSet: payload.message === 'Password already set',
        invitationVerified: true,
      };
    }
    case AuthActionTypes.AUTHENTICATE_REQ: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case AuthActionTypes.AUTHENTICATE_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          error,
        };
      }
      localStorage.setItem('token', JSON.stringify(payload.token));
      localStorage.setItem('refreshToken', JSON.stringify(payload.refreshToken));
      localStorage.setItem('user', JSON.stringify(payload.user));
      return {
        ...state,
        loading: false,
        error: false,
        isAuthenticated: true,
      };
    }
    default:
      return state;
  }
};

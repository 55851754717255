import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Card, Carousel } from 'react-bootstrap';

const renderCarouselItems = (supervisors) => {
  return supervisors.map((supervisor) => {
    return (
      <Carousel.Item key={supervisor.lastName}>
        <img className="d-block w-100" src={supervisor.profileImage} alt="First slide" />
        <Carousel.Caption>
          <h3 className="name">
            {supervisor.firstName}
            &nbsp;
            {supervisor.lastName}
          </h3>
          <h4 className="poppins-light">{supervisor.jobTitle}</h4>
          <a className="poppins-light email" href={`mailto: ${supervisor.email}`}>
            {supervisor.email}
          </a>
          <p className="description">{supervisor.aboutMe}</p>
        </Carousel.Caption>
      </Carousel.Item>
    );
  });
};

const Supervisors = () => {
  const { supervisors } = useSelector(
    (_S) => ({
      supervisors: _S.DashboardReducer.supervisors,
    }),
    shallowEqual,
  );

  return (
    <div className="client">
      <Card>
        <Card.Header className="">Supervisors</Card.Header>
      </Card>
      <Card>
        <Card.Body>
          <Carousel>{renderCarouselItems(supervisors)}</Carousel>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Supervisors;
